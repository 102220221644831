<template>
  <div class="stage-level-detail pa-3">
    <v-btn
      v-if="isMyTimelineObservatory"
      class="mb-2 font-weight-bold"
      color="rgb(35, 205, 99)"
      dark
      @click="sendMyTimeline()"
    >
      この場所を選択する
    </v-btn>
    <div class="caption text--secondary">
      {{ selectedObservatory.address }}
    </div>
    <div class="caption text--secondary">
      {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
    </div>
    <v-card
      class="d-flex align-center my-2"
      flat
      tile
      v-if="
        selectedObservatory.cameraInfo !== undefined &&
          selectedObservatory.cameraInfo.images.length > 0
      "
    >
      <v-btn
        class="mr-3"
        small
        :color="isCamera ? '' : 'primary'"
        @click="isCamera = false"
      >
        水位・雨量
      </v-btn>
      <v-btn small :color="isCamera ? 'primary' : ''" @click="isCamera = true">
        カメラ
      </v-btn>
    </v-card>
    <div v-show="!isCamera">
      <div v-if="!isDeficit" class="my-2">
        <div class="d-flex align-center">
          <v-card
            width="120"
            class="caption text--secondary mr-3"
            flat
            v-if="forecasts.length > 0"
          >
            予測期間
          </v-card>
          <v-card width="120" class="caption text--secondary" flat>
            時間表示
          </v-card>
        </div>
        <v-card class="d-flex align-center" flat tile>
          <v-btn-toggle
            v-if="forecasts.length > 0"
            v-model="displayHours"
            mandatory
            dense
            class="mr-3"
          >
            <v-btn small :value="6">
              6時間
            </v-btn>
            <v-btn small :value="36">
              36時間
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle v-model="displayInterval" mandatory dense>
            <v-btn small :value="10">
              10分毎
            </v-btn>
            <v-btn small :value="60">
              時間毎
            </v-btn>
          </v-btn-toggle>
        </v-card>
      </div>
      <div class="caption ml-2 my-1">{{ `(mm/h)` }}</div>
      <StageRainChart
        v-if="!isDeficit"
        :height="70"
        :timeSeries="rainTimeSeries"
        :baseDate="baseDateJst"
        :minDate="minDateJst"
        :maxDate="maxDateJst"
      />
      <v-card height="10" tile flat></v-card>
      <div class="caption ml-2 my-1">（{{ levelUnit }}）</div>
      <StageChart
        v-if="!isDeficit"
        :height="200"
        :selectedObservatory="selectedObservatory"
        :timeSeries="waterTimeSeries"
        :baseDate="baseDateJst"
        :minDate="minDateJst"
        :maxDate="maxDateJst"
      />
      <div class="mt-3">
        <div class="caption">基準水位</div>
        <v-row
          dense
          no-gutters
          class="body-2"
          v-for="std in standardLevels"
          :key="std.label"
        >
          <v-spacer />
          <v-col cols="1">
            <div
              class="std-color"
              :style="{ 'border-bottom-color': std.color }"
            ></div>
          </v-col>
          <v-col cols="6" class="caption">{{ std.label }}</v-col>
          <v-col cols="3" class="caption text-right">
            {{ std.level == undefined ? "---" : std.level + " " + levelUnit }}
          </v-col>
          <v-spacer />
        </v-row>
      </div>
      <v-simple-table dense class="mt-3">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">日時</th>
              <th class="text-left">水位 ({{ levelUnit }})</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(ts, i) in waterTimeSeries">
              <tr
                v-if="ts.value !== null"
                :key="i"
                :class="{ forecast: ts.type === 'forecast' }"
              >
                <td>{{ ts.time }}</td>
                <td>
                  <v-chip
                    label
                    :color="getColor(ts.value)"
                    :dark="getColor(ts.value) !== 'transparent'"
                    style="height: 22px"
                  >
                    {{
                      ts.value === undefined
                        ? "---"
                        : parseFloat(ts.value).toFixed(2)
                    }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <div class="mt-5 caption" v-if="isDeficit">
        この観測所は欠測しています...
      </div>
    </div>
    <div v-show="isCamera">
      <CameraChart />
    </div>
    <v-dialog v-model="dialogMyTimeLineConfirm" persistent width="280">
      <v-card>
        <v-card-title class="body-2">
          別の水位観測所も追加しますか？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeMyTimelineConfirm()">
            キャンセル
          </v-btn>
          <v-btn color="green darken-1" text @click="continueMyTimeline()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StageChart from "./StageChart";
import StageRainChart from "./StageRainChart";
import CameraChart from "./CameraChart";
import { OBSERVATORY, STAGES } from "../../../enums/Type";
import { isPrivate, mytimelineUrl } from "../../../utils/common";
import moment from "moment";
import * as axiosHelper from "../../../utils/axiosHelper";

export default {
  name: "StageDetail",
  components: {
    StageChart,
    StageRainChart,
    CameraChart
  },
  data() {
    return {
      isPrivate,
      isCamera: false,
      standardLevels: [],
      displayHours: 6,
      displayInterval: 10,
      waterTimeSeries: [],
      rainTimeSeries: [],
      dialogMyTimeLineConfirm: false
    };
  },
  watch: {
    displayHours() {
      this.makeTimeSeries();
    },
    displayInterval() {
      this.makeTimeSeries();
    }
  },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    levelUnit() {
      return this.selectedObservatory.kind === 9 ? "TP.m" : "m";
    },
    rainVolumes() {
      if (this.selectedObservatory.rainVolumes === undefined) {
        return [];
      }
      return this.selectedObservatory.rainVolumes;
    },
    waterVolumes() {
      if (this.selectedObservatory.waterVolumes === undefined) {
        return [];
      }
      return this.selectedObservatory.waterVolumes;
    },
    forecasts() {
      return this.waterVolumes.filter(v => v.type === "forecast");
    },
    isDeficit() {
      return this.waterVolumes === 0;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    baseDateJst() {
      const baseDate = this.baseDate.clone();
      return baseDate.local();
    },
    currentJst() {
      const baseDate = this.baseDate.clone();
      baseDate.subtract(baseDate.minute() % this.displayInterval, "minutes");
      return baseDate.local();
    },
    isHourInterval() {
      return this.displayInterval === 60;
    },
    minDateJst() {
      const realHours = this.isHourInterval ? 23 : 3;
      return this.currentJst.clone().subtract(realHours, "hours");
    },
    maxDateJst() {
      let forecastHours = this.isHourInterval ? 3 : 0.5;
      if (this.isPrivate === true) {
        forecastHours = this.displayHours;
      }
      return this.currentJst.clone().add(forecastHours, "hours");
    },
    isMyTimelineObservatory() {
      return (
        this.$route.query.mytimeline_observatory &&
        this.$route.query.mytimeline_observatory === "1"
      );
    }
  },
  mounted() {
    this.makeTimeSeries();
    this.standardLevels = [];
    if (this.selectedObservatory.kind === 9) {
      this.standardLevels.push({
        label: "氾濫開始水位",
        color: STAGES.OUTBREAK.color,
        level: this.selectedObservatory.outbreakLevel
      });
      this.standardLevels.push({
        label: "危険水位",
        color: STAGES.DANGEROUS.color,
        level: this.selectedObservatory.dangerousLevel
      });
      this.standardLevels.push({
        label: "観測開始水位",
        color: STAGES.START.color,
        level: this.selectedObservatory.startStage
      });
    } else {
      this.standardLevels.push({
        label: STAGES.DANGEROUS.label,
        color: STAGES.DANGEROUS.color,
        level: this.selectedObservatory.dangerousLevel
      });
      this.standardLevels.push({
        label: STAGES.EVACUATION.label,
        color: STAGES.EVACUATION.color,
        level: this.selectedObservatory.evacuationLevel
      });
      this.standardLevels.push({
        label: STAGES.WARNING.label,
        color: STAGES.WARNING.color,
        level: this.selectedObservatory.warningLevel
      });
      this.standardLevels.push({
        label: STAGES.STANDBY.label,
        color: STAGES.STANDBY.color,
        level: this.selectedObservatory.standbyLevel
      });
    }
    this.syncNearCamera();
  },
  methods: {
    async syncNearCamera() {
      if (this.selectedObservatory.cameraInfo) {
        return;
      }
      const target_id = this.selectedObservatory._id;
      const observatories = this.$store.getters.observatories;
      const target = observatories.find(
        o => o.type === OBSERVATORY.STAGE.code && o._id === target_id
      );
      if (target === undefined || target.near === undefined) {
        return;
      }
      const camera = target.near.find(n => n.type === OBSERVATORY.CAMERA.code);
      if (camera === undefined || camera.distance > 120) {
        return;
      }
      const camera_id = camera._id;
      const params = {
        date: this.baseDate.format("YYYY/MM/DD HH:mm"),
        observatory_id: camera_id,
        type: OBSERVATORY.CAMERA.code,
        mobile: 1
      };
      const response = await axiosHelper.get("/observatories/data", params);
      if (response === null) {
        return;
      }
      const cameraInfo = response.observatories.find(
        obs => obs._id === camera_id
      );
      if (cameraInfo === undefined) {
        return;
      }
      const selected = Object.assign({}, this.selectedObservatory);
      selected.cameraInfo = {};
      selected.cameraInfo.images = cameraInfo.imageInfo;
      this.$store.commit("SET_SELECTED_OBSERVATORY", selected);
    },
    makeTimeSeries() {
      if (this.waterVolumes.length === 0 && this.rainVolumes.length === 0) {
        this.rainTimeSeries = [];
        this.waterTimeSeries = [];
        return;
      }

      // water
      this.waterTimeSeries = this.waterVolumes
        .map(v => {
          const jst = moment.utc(v.dateTime, "YYYY/MM/DD HH:mm").local();
          if (this.isHourInterval && v.type === "real" && jst.minutes() !== 0) {
            return null;
          }
          if (jst.isBetween(this.minDateJst, this.maxDateJst) === false) {
            return null;
          }
          return {
            jst: jst.toDate(),
            time: jst.format("YYYY/MM/DD HH:mm"),
            type: v.type,
            value: v.level
          };
        })
        .filter(volume => volume !== null);

      // rain
      this.rainTimeSeries = this.rainVolumes
        .map(v => {
          const jst = moment.utc(v.dateTime, "YYYY/MM/DD HH:mm").local();
          if (this.isHourInterval && v.type === "real" && jst.minutes() !== 0) {
            return null;
          }
          if (jst.isBetween(this.minDateJst, this.maxDateJst) === false) {
            return null;
          }
          const value =
            this.isHourInterval && v.type === "real" ? v.min60 : v.value;
          if (value === undefined || value === -999) {
            return null;
          }
          return {
            jst: jst.toDate(),
            time: jst.format("YYYY/MM/DD HH:mm"),
            type: v.type,
            value: value
          };
        })
        .filter(volume => volume !== null);
    },
    getColor(level) {
      const obs = this.selectedObservatory;
      if (level === undefined || level === -999) {
        return "#bdbdbd";
      }
      if (
        obs.kind === 9 &&
        obs.outbreakLevel !== undefined &&
        level >= obs.outbreakLevel
      ) {
        return STAGES.OUTBREAK.color;
      }
      if (obs.dangerousLevel !== undefined && level >= obs.dangerousLevel) {
        return STAGES.DANGEROUS.color;
      }
      if (obs.kind === 9) {
        if (obs.startStage !== undefined && level >= obs.startStage) {
          return STAGES.START.color;
        }
      } else {
        if (obs.evacuationLevel !== undefined && level >= obs.evacuationLevel) {
          return STAGES.EVACUATION.color;
        }
        if (obs.warningLevel !== undefined && level >= obs.warningLevel) {
          return STAGES.WARNING.color;
        }
        if (obs.standbyLevel !== undefined && level >= obs.standbyLevel) {
          return STAGES.STANDBY.color;
        }
      }
      return "transparent";
    },
    sendMyTimeline() {
      if (window.opener === undefined || window.opener == null) {
        return;
      }
      let osname = this.selectedObservatory.name.replace("(危)", "");
      if (
        this.selectedObservatory.kind !== 9 &&
        this.selectedObservatory.riverName
      ) {
        osname += " (" + this.selectedObservatory.riverName + ")";
      }

      window.opener.postMessage(osname, mytimelineUrl);
      this.dialogMyTimeLineConfirm = true;
    },
    closeMyTimelineConfirm() {
      this.dialogMyTimeLineConfirm = false;
      window.close();
      window.opener.focus();
    },
    continueMyTimeline() {
      this.dialogMyTimeLineConfirm = false;
      this.$store.commit("SET_MAP_DETAIL", {
        isShowing: false,
        contents: "",
        title: ""
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.stage-level-detail {
  height: 80vh;
  thead {
    background-color: #addff3;
  }
  tbody td {
    background: #eee;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .std-color {
    width: 16px;
    height: 12px;
    border-bottom: white 3px solid;
  }
  .forecast {
    color: blue;
  }
}
</style>
