<template>
  <l-map
    ref="leafletMap"
    :min-zoom="minZoom"
    :max-zoom="maxZoom"
    :zoom="zoom"
    :center="centerPosition"
    :options="options"
    :zoom-animation="false"
    :preferCanvas="true"
    @click="popupHazardInfo"
  >
    <BaseTileLayer />
    <HazardMapLayer />
    <l-marker v-if="currentCoordinate != null" :lat-lng="currentCoordinate">
      <l-icon
        :icon-size="[20, 40]"
        :icon-anchor="[10, 40]"
        :icon-url="require('@/assets/images/png/main-contents-pin.png')"
      />
    </l-marker>

    <l-geo-json
      :geojson="outlineGeojson"
      :optionsStyle="{
        weight: 2,
        color: '#000000',
        opacity: 1,
        fillColor: 'rgba(0,0,0,0)',
        fillOpacity: 0.2,
        'z-index': 5000
      }"
    ></l-geo-json>

    <l-tile-layer
      layer-type="overlay"
      :url="
        'https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/river/{z}/{x}/{y}.png'
      "
      :attribution="geoApiAttribution"
    ></l-tile-layer>

    <template v-if="selectedRisk.key === Risk.OBSERVATORY.key">
      <ObservatoryLayer />
      <RainLayer v-if="isShowObservatoryRadar === true" />
    </template>
    <template v-if="selectedRisk.key === Risk.RAIN.key">
      <RainLayer />
    </template>
    <template v-if="selectedRisk.key === Risk.DOSYA.key">
      <DosyaLayer />
    </template>
    <template v-if="selectedRisk.key === Risk.KOUZUI.key">
      <KouzuiLayer />
    </template>
    <template v-if="selectedRisk.key === Risk.RIVER_FLOODING.key">
      <LGridLayer :tile-component="grayTileLayer" v-if="false" />
      <RiverFloodingLayer />
      <RiskLineLayer />
    </template>

    <l-control position="topleft" style="pointer-events: none;">
      <div style="display: inline-flex" class="mt-1">
        <v-btn
          small
          color="secondary"
          @click="getCurrentPosition()"
          style="pointer-events: painted;"
          class="mr-1"
        >
          <v-icon>mdi-map-marker-radius</v-icon>
        </v-btn>
        <v-btn
          small
          color="secondary"
          @click="showInformation()"
          style="pointer-events: painted;"
          v-if="informationMessage"
        >
          お知らせ
        </v-btn>
      </div>

      <template v-if="selectedRisk.key === Risk.RAIN.key">
        <div class="sub-control" style="width: 125px">
          <RainControl />
        </div>
      </template>
      <template v-if="selectedRisk.key === Risk.DOSYA.key">
        <div class="sub-control" style="width: 125px">
          <DosyaSubControlLayer />
        </div>
      </template>
      <template v-if="selectedRisk.key === Risk.OBSERVATORY.key">
        <div class="mt-2">
          <ObservatoryControl ref="observatoryControl" />
        </div>
      </template>

      <ShelterLayer />
      <ShelterExLayer />

      <div class="mt-2" v-if="isShowHazardMapLayer">
        <v-btn
          small
          color="secondary"
          @click="switchShowHazardMapControl(!isShowHazardMapControl)"
          style="pointer-events: painted;"
        >
          <div class="mr-3">凡例</div>
          <v-icon v-if="!isShowHazardMapControl">mdi-chevron-down</v-icon>
          <v-icon v-if="isShowHazardMapControl">mdi-chevron-up</v-icon>
        </v-btn>
      </div>
      <HazardMapControl class="mt-2" />
    </l-control>

    <l-control position="topright" style="pointer-events: none;">
      <AddressSearchControl />
      <ZoomControl style="margin-top: 50px" />
    </l-control>

    <v-card
      v-if="isMytimeline || isMyTimelineShelter"
      color="rgb(35, 205, 99)"
      class="pa-2 body-2 font-weight-bold white--text text-center"
      width="280px"
      style="position: absolute; left: calc(50% - 150px); top: 100px; z-index: 6000"
    >
      <div v-if="isMyTimelineShelter">
        <div class="d-flex justify-center">
          <div>避難所マーク(</div>
          <v-img
            :src="require('@/assets/images/png/shelter.png')"
            max-width="24"
            width="20"
            height="20"
            contain
            class="pa-0 ma-0"
          />
          <div>)を選択(タップ)</div>
        </div>
        <div class="mb-2">してください</div>
        <div style="font-size: 11px; line-height: 14px;">
          <div>{{ "避難所の開設情報については" }}</div>
          <div>{{ "市町村HP等で確認してください" }}</div>
        </div>
      </div>
      <div v-else>自宅の場所を選択(タップ)してください</div>
    </v-card>

    <l-control position="bottomleft">
      <v-btn class="text-subtitle-1" @click="showMenu()">
        {{ !selectedRisk.key ? "未選択" : selectedRisk.title }}
      </v-btn>
    </l-control>

    <l-control position="bottomright" style="pointer-events: none;">
      <UsageGuidePanel
        class="mr-2"
        v-if="isShowUsageGuidePanel && selectedRisk.key !== undefined"
      />
      <div class="text-right mt-1" v-if="selectedRisk.key !== undefined">
        <v-btn
          small
          class="px-2"
          color="secondary"
          @click="switchUsageGuidePanel(!isShowUsageGuidePanel)"
          style="pointer-events: painted;"
        >
          <div class="mr-1">凡例</div>
          <v-icon v-if="!isShowUsageGuidePanel">mdi-chevron-down</v-icon>
          <v-icon v-if="isShowUsageGuidePanel">mdi-chevron-up</v-icon>
        </v-btn>
      </div>
    </l-control>
    <v-dialog v-model="informationDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h6">
          お知らせ
        </v-card-title>
        <v-card-text v-if="informationMessage">
          <div v-html="informationMessage"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="informationDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </l-map>
</template>

<script>
import {
  LMap,
  LControl,
  LIcon,
  LMarker,
  LTileLayer,
  LGeoJson,
  LGridLayer
} from "vue2-leaflet";
import axios from "axios";
import L from "leaflet";
import { Risk } from "../enums/Risk";
import BaseTileLayer from "./base-map/layers/BaseTileLayer";
import ZoomControl from "./base-map/ZoomControl";
import AddressSearchControl from "./base-map/AddressSearchControl";
import UsageGuidePanel from "./base-map/UsageGuidePanel";
import ObservatoryLayer from "./base-map/layers/ObservatoryLayer";
import ObservatoryControl from "./base-map/layers/ObservatoryControl";
import HazardMapLayer from "./base-map/layers/HazardMapLayer";
import HazardMapControl from "./base-map/layers/HazardMapControl";
import RainLayer from "./base-map/layers/RainLayer";
import RainControl from "./base-map/layers/RainControl";
import DosyaLayer from "./base-map/layers/DosyaLayer";
import DosyaSubControlLayer from "./base-map/layers/DosyaSubControlLayer";
import KouzuiLayer from "./base-map/layers/KouzuiLayer";
import RiverFloodingLayer from "./base-map/layers/RiverFloodingLayer";
import RiskLineLayer from "./base-map/layers/RiskLineLayer";
import GrayTileLayer from "./base-map/layers/GrayTileLayer";
import ShelterLayer from "./base-map/layers/ShelterLayer";
import ShelterExLayer from "./base-map/layers/ShelterExLayer";
import { mytimelineUrl } from "../utils/common";

export default {
  name: "BaseMap",
  components: {
    UsageGuidePanel,
    ObservatoryControl,
    RainControl,
    ZoomControl,
    AddressSearchControl,
    RainLayer,
    ObservatoryLayer,
    HazardMapLayer,
    HazardMapControl,
    LMap,
    BaseTileLayer,
    LControl,
    LIcon,
    LMarker,
    LTileLayer,
    LGeoJson,
    DosyaLayer,
    DosyaSubControlLayer,
    KouzuiLayer,
    RiverFloodingLayer,
    RiskLineLayer,
    LGridLayer,
    ShelterLayer,
    ShelterExLayer
  },
  data() {
    return {
      Risk,
      minZoom: 7,
      maxZoom: 17,
      options: { zoomControl: false },
      zoom: 13,
      centerPosition: [36.39155, 139.060447],
      currentCoordinate: null,
      geoApiAttribution:
        '<a href="http://geoapi.heartrails.com/">HeartRails Geo API</a>',
      outlineGeojson: null,
      informationDialog: false,
      informationMessage: null,
      isFirstMove: true,
      popup: L.popup({ maxWidth: 320 }),
      grayTileLayer: GrayTileLayer
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    isShowHazardMapLayer() {
      return this.$store.getters.selectedHazardMaps.length > 0;
    },
    isShowHazardMapControl() {
      return this.$store.getters.isShowHazardMapControl;
    },
    isShowUsageGuidePanel() {
      return this.$store.getters.isShowUsageGuidePanel;
    },
    isShowObservatorySubControl() {
      return this.$store.getters.isShowObservatorySubControl;
    },
    map() {
      return this.$refs.leafletMap.mapObject;
    },
    isShowObservatoryRadar() {
      return this.$store.getters.isShowObservatoryRadar;
    },
    isMytimeline() {
      return this.$store.getters.isMytimeline;
    },
    isMyTimelineShelter() {
      return (
        this.$route.query.mytimeline_shelter &&
        this.$route.query.mytimeline_shelter === "1"
      );
    }
  },
  watch: {
    isShowObservatorySubControl() {
      if (this.isShowObservatorySubControl === true) {
        this.switchShowHazardMapControl(false);
      }
    }
  },
  async created() {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });

    const outline = await axios.get(
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/geojson/outline.geojson"
    );
    this.outlineGeojson = outline.data;

    const sys_msg = await axios.get(
      "https://d1gpfmzkl8v4u5.cloudfront.net/system/public/m/sys_msg.json"
    );
    if (sys_msg.data && sys_msg.data.html) {
      this.informationMessage = sys_msg.data.html;
    } else {
      this.informationMessage = null;
    }
  },
  mounted() {
    this.$store.commit("SET_LEAFLET_MAP", this.$refs.leafletMap.mapObject);
    this.map.on("moveend", this.saveMapPosition);
    this.map.on("zoomend", this.saveMapPosition);
    L.control
      .scale({ imperial: false, position: "bottomright" })
      .addTo(this.map);

    if (
      this.isMyTimelineShelter === true &&
      this.$route.query.lat &&
      this.$route.query.lng
    ) {
      const lat = parseFloat(this.$route.query.lat);
      const lng = parseFloat(this.$route.query.lng);
      this.currentCoordinate = [lat, lng];
    }
  },
  beforeDestroy() {
    this.map.off("moveend", this.saveMapPosition);
    this.map.off("zoomend", this.saveMapPosition);
  },
  methods: {
    resetCenterPosition() {
      this.$refs.leafletMap.mapObject.setView(this.centerPosition, 13);
    },
    getCurrentPosition() {
      if (!navigator.geolocation) {
        alert(
          "現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。"
        );
        return;
      }

      this.$store.commit("SET_IS_LOADING", true);
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      };
      navigator.geolocation.getCurrentPosition(
        this.success,
        this.error,
        options
      );
    },
    success(position) {
      this.$store.commit("SET_IS_LOADING", false);
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      this.currentCoordinate = [lat, lng];
      this.$refs.leafletMap.mapObject.setView([lat, lng], 14);
    },
    error(error) {
      this.$store.commit("SET_IS_LOADING", false);
      switch (error.code) {
        case 1: //PERMISSION_DENIED
          alert("位置情報が許可されていませんので、設定を変更してください");
          break;
        case 2: //POSITION_UNAVAILABLE
          alert("現在位置が取得できませんでした");
          break;
        case 3: //TIMEOUT
          alert("タイムアウトになりました");
          break;
        default:
          alert("現在位置が取得できませんでした");
          break;
      }
    },
    switchShowHazardMapControl(isShow) {
      this.$store.commit("SET_IS_SHOW_HAZARD_MAP_CONTROL", isShow);
    },
    switchUsageGuidePanel(isShow) {
      this.$store.commit("SET_IS_SHOW_USAGE_GUIDE_PANEL", isShow);
    },
    showMenu() {
      this.$emit("showMenu");
    },
    saveMapPosition() {
      if (this.isFirstMove === true) {
        this.isFirstMove = false;
        return;
      }
      const data = {
        zoom: this.map.getZoom(),
        lat: this.map.getCenter().lat,
        lng: this.map.getCenter().lng
      };
      localStorage.setItem("map-position", JSON.stringify(data));
    },
    showInformation() {
      this.informationDialog = true;
    },
    async popupHazardInfo(e) {
      if (this.$store.getters.bulkHazard !== true) {
        return;
      }
      const lat = e.latlng.lat;
      const lng = e.latlng.lng;
      this.popup
        .setLatLng(e.latlng)
        .setContent("Loading...")
        .openOn(this.map);
      const url = `https://common-api.suibou-gunma.jp/hazard?lat=${lat}&lng=${lng}`;
      const response = await axios.get(url);
      if (
        response === null ||
        response.data === undefined ||
        response.data.contents === undefined
      ) {
        this.map.closePopup();
        return;
      }
      const contents = response.data.contents;
      const getContent = c => {
        let title = c.name;
        if (title.includes("土砂災害警戒区域等・")) {
          title = c.name.replace("土砂災害警戒区域等・", "");
        }
        if (title.includes("家屋倒壊等氾濫想定区域・")) {
          title = c.name.replace("家屋倒壊等氾濫想定区域・", "");
        }
        const titleClass =
          c.name.includes("土砂災害警戒区域等") ||
          c.name.includes("家屋倒壊等氾濫想定区域")
            ? "popup-dosya-title"
            : "popup-title";
        let val = c.value === "" ? "なし" : c.value;
        if (c.rgb) {
          const rgb = c.rgb.join(",");
          const fontColor =
            rgb === "180,0,104" ||
            rgb === "0,65,255" ||
            rgb === "96,0,96" ||
            val === "特別警戒区域"
              ? "white"
              : "black";
          val = `<span style="color: ${fontColor}; padding: 0 5px; background: rgb(${rgb})">${val}</span>`;
        }
        return (
          '<div class="d-flex">' +
          `<div class="${titleClass}">${title}</div>` +
          "<div>：</div>" +
          `<div>${val}</div>` +
          "</div>"
        );
      };
      let popupContent = '<div id="popup-hazard">';
      popupContent +=
        '<div class="font-weight-bold">この場所の自然災害リスクは？</div>';
      let content = contents.find(c => c.name === "洪水浸水想定区域(想定最大)");
      if (content !== undefined) {
        popupContent += getContent(content);
      }
      content = contents.find(c => c.name === "浸水継続時間(想定最大)");
      if (content !== undefined) {
        popupContent += getContent(content);
      }
      popupContent += "<div>家屋倒壊等氾濫想定区域</div>";
      content = contents.find(c => c.name === "家屋倒壊等氾濫想定区域・氾濫流");
      if (content !== undefined) {
        popupContent += getContent(content);
      }
      content = contents.find(
        c => c.name === "家屋倒壊等氾濫想定区域・河岸侵食"
      );
      if (content !== undefined) {
        popupContent += getContent(content);
      }
      popupContent += "<div>土砂災害警戒区域等</div>";
      content = contents.find(c => c.name.includes("急傾斜地の崩壊"));
      if (content !== undefined) {
        popupContent += getContent(content);
      }
      content = contents.find(c => c.name.includes("土石流"));
      if (content !== undefined) {
        popupContent += getContent(content);
      }
      content = contents.find(c => c.name.includes("地すべり"));
      if (content !== undefined) {
        popupContent += getContent(content);
      }

      popupContent += "</div>";
      this.popup.setContent(popupContent);

      // マイタイムライン連携
      if (this.isMytimeline === true) {
        const popupHazard = document.getElementById("popup-hazard");
        popupHazard.insertAdjacentHTML(
          "beforeend",
          '<div id="popup-hazard-button">この場所を選択する</div>'
        );
        const popupHazardButton = document.getElementById(
          "popup-hazard-button"
        );
        popupHazardButton.onclick = async () => {
          if (window.opener === undefined || window.opener == null) {
            return;
          }
          const targetContents = contents
            .filter(c => c.name !== "洪水浸水想定区域(計画)" && c.value !== "")
            .map(c => {
              return { name: c.name, value: c.value };
            });
          const message = {
            contents: targetContents,
            lat: lat,
            lng: lng
          };

          window.opener.postMessage(message, mytimelineUrl);
          setTimeout(() => {
            window.close();
            window.opener.focus();
          }, 500);
        };
      }

      if (this.selectedRisk.key === Risk.OBSERVATORY.key) {
        this.$refs.observatoryControl.isExpanded = false;
        this.$store.commit("SET_IS_SHOW_OBSERVATORY_SUB_CONTROL", false);
      }
      this.$store.commit("SET_IS_SHOW_HAZARD_MAP_CONTROL", false);
      this.$store.commit("SET_IS_SHOW_USAGE_GUIDE_PANEL", false);

      // 地点から下へずらす
      const offset = 150;
      const L = 85.05112878; // 最大緯度
      const zoom = parseInt(this.map.getZoom());
      const pixelX = parseInt(Math.pow(2, zoom + 7) * (lng / 180 + 1));
      const pixelY =
        parseInt(
          (Math.pow(2, zoom + 7) / Math.PI) *
            (-1 * Math.atanh(Math.sin((Math.PI / 180) * lat)) +
              Math.atanh(Math.sin((Math.PI / 180) * L)))
        ) - offset;
      const lng2 = 180 * (pixelX / Math.pow(2, zoom + 7) - 1);
      const lat2 =
        (180 / Math.PI) *
        Math.asin(
          Math.tanh(
            ((-1 * Math.PI) / Math.pow(2, zoom + 7)) * pixelY +
              Math.atanh(Math.sin((Math.PI / 180) * L))
          )
        );
      this.map.panTo([lat2, lng2]);
    }
  }
};
</script>

<style lang="scss">
.leaflet-popup-content {
  margin: 18px 18px 5px 8px !important;
}
.popup-title {
  width: 170px;
}
.popup-dosya-title {
  padding-left: 10px;
  width: 110px;
}
#popup-hazard-button,
#popup-shelter-button {
  margin: 16px 0;
  padding: 12px 3px;
  width: 100%;
  background-color: rgb(35, 205, 99);
  color: white;
  border-radius: 0.5rem;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
}
.leaflet-image-layer {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>

<style scoped lang="scss">
.sub-control {
  pointer-events: painted;
  margin-top: 10px;
  padding: 3px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
</style>
